import React from 'react';
import ImportUsersContainer from './ImportUsersContainer';

class ImportUsersScreen extends React.Component {

    public render() {
        return <ImportUsersContainer />
    }

}

export default ImportUsersScreen;