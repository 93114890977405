import Urls from "./Urls";
import {AuthManager} from "@cuatroochenta/co-admin-react-library";

export default class TaskImportUsers {

    private getPostInfo(file: File) {

        const formData = new FormData();
        formData.append("users-excel", file);

        return {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + AuthManager.getAuthToken()
            },
            body: formData
        }
    }

    public get(file: File) {
        return fetch(Urls.URL_IMPORT_USERS, this.getPostInfo(file))
            .then(response => response.json())
            .then(data => {return data});
    }
}