import React from 'react';
import ImportUsersView from "./ImportUsersView";
import TaskImportUsers from "../../ws/TaskImportUsers";

export interface ResponseUsers {
    success: boolean
    message: string | null
    error: string | null
}

interface state {
    file: File | null
    response: ResponseUsers | null
}

class ImportUsersContainer extends React.Component {

    public state: state = {file: null, response: null};

    public importUsers(file: File, stopLoading: () => void) {
        new TaskImportUsers().get(file)
            .catch(
                (response) => {
                    this.setState({response: {success:false, error: response.message} as ResponseUsers});
                }
            )
            .then(
                (response) => {
                    if (response !== undefined) {
                        this.setState({response: response});
                    }
                    stopLoading();
                }
            )
    }

    public render() {
        return <ImportUsersView
            importUsers={this.importUsers.bind(this)}
            response={this.state.response}
        />;
    }

}

export default ImportUsersContainer;