import React from 'react';
import { FormRenderData, Module } from '@cuatroochenta/co-admin-react-library';
import ImportUsersScreen from "./actions/modules/importUsers/ImportUsersScreen";
import CustomAction from './CustomAction';

import '@cuatroochenta/co-admin-react-library/lib/index.css';
import './res/scss/custom-styles.scss'

interface Filters {
    [dynamic: string]: string
}

class App extends React.Component {

    private beforeFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    private afterFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        if (entity === 'user') {
            if (data.relationFormValues &&
                data.relationFormValues.userDocuments && data.relationFormValues.userDocuments.length !== 0 &&
                data.relationFormValues.userResidents && data.relationFormValues.userResidents.length !== 0
            ) {
                const documentsData = data.relationFormValues.userDocuments;
                const visibleResidentIds: number[] = [];
                data.relationFormValues.userResidents.forEach((userResident: any) => {
                    if (userResident.visible) {
                        visibleResidentIds.push(userResident.resident);
                    }
                });
                const documentsTabNode = document.getElementById('relations-tabpane-userDocuments');
                if (!documentsTabNode) {
                    return null;
                }
                const documentCardsNode = documentsTabNode.getElementsByClassName('card');
                Array.from(documentCardsNode).forEach((documentCard, indexDocumentCard) => {
                    const residentId = documentsData[indexDocumentCard].resident;
                    visibleResidentIds.includes(residentId) ?
                        documentCard.classList.remove('d-none') :
                        documentCard.classList.add('d-none');
                });
            }
        }
        return null;
    };

    private customButtonsList = (entity: string, filters: Filters, orderby: string, order: string): React.ReactNode => {
        return null;
    };

    private afterLoginButton = (): React.ReactNode => {
        return null;
    };

    public render() {
        const languages = [
            {name: 'Español', code: 'es'},
            {name: 'English', code: 'en'},
        ]

        return (
            <Module
                actions={[
                    {name: 'importUsers', element: <ImportUsersScreen/>}
                ]}
                beforeFormView={this.beforeFormView}
                afterFormView={this.afterFormView}
                customButtonsList={this.customButtonsList}
                locale={'es'}
                afterLoginButton={this.afterLoginButton}
                languages={languages}
            />
        );
    }

}

export default App;
