import React from 'react';
import {ResponseUsers} from "./ImportUsersContainer";
import {LoadingView} from "@cuatroochenta/co-admin-react-library";

interface ImportUsersViewProps {
    importUsers: (file: File, stopLoading: () => void) => void
    response: ResponseUsers|null
}

interface state {
    file: File | null
    loading: boolean
}

class ImportUsersView extends React.Component<ImportUsersViewProps> {

    public state:state = { file: null, loading: false};

    private renderFileSelector(): React.ReactNode {
        return (
            <div className="col-md-6">
                <label>Archivo</label>
                <div className="upload-file">
                    <input type="file"
                           className="form-control"
                           onChange={(e) => this.onUploadFile( e.target.files ? e.target.files[0] : null )}
                           disabled={false}
                           title={
                               this.state.file ?
                                   this.state.file.name :
                                   "No se ha seleccionado ningún archivo"
                           }
                    />
                </div>
            </div>
        );
    }

    public onUploadFile(file: File | null): void {
        this.setState({file : file});
    }


    importUsers(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault()
        if (this.state.file !== null && !this.state.loading) {
            this.setState({loading: true});
            this.props.importUsers(this.state.file, () => {this.setState({loading: false})})
        }
    }

    private renderGenerateButton() {
        return (
            <div className="row">
                <div className="col">
                    <button
                        type="submit"
                        className="btn mt-4"
                        style={{
                            background: "rgb(141, 96, 133)",
                            border: "1px solid rgb(141, 96, 133)",
                            color: "rgb(255, 255, 255)",
                          }}
                    >
                        <i className='fa fa-qrcode d-inline-block d-sm-none' />
                        <span className="d-none d-sm-inline-block">
                            Importar Usuarios
                        </span>
                    </button>
                </div>
            </div>
        )
    }

    private renderMessageCard() {
        if(this.props.response === null || this.state.loading) {
            return null;
        }

        if (this.props.response.success) {
            return this.renderMessage(this.props.response.message, { color: "green", fontSize: "1.4em", fontWeight: "bold"});
        }

        return this.renderMessage(this.props.response.error, {color: "red"});
    }

    private renderMessage(message:string|null, style:{} = {}) {
        return (
            <div className="col-md-12">
                <div className="card shadow p-3">
                    <div className="card-body">
                        <div className="message">
                            <label style={style}>{message}</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public render() {
        if(this.state.loading) {
            return <LoadingView loading={this.state.loading}/>;
        }

        return (
            <div>
                {this.renderMessageCard()}
                <div className="col-md-12">
                    <div className="card shadow p-3">
                        <div className="card-body">
                            <form onSubmit={(e) => this.importUsers(e)}>
                                <div className={"row"}>
                                {this.renderFileSelector()}
                                </div>
                                {this.renderGenerateButton()}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportUsersView;